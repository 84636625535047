import { Playlist } from './playlist';
import { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { getAllPlaylists, getPlaylist } from '../../api';
import { MainToolbar } from './main-toolbar';
import { useParams } from 'react-router';
import { Controls } from './controls';
import { PlaylistsModal } from './playlists-modal';
import { PlaylistToolbar } from './playlist-toolbar';
import {
    generateEmojiLink,
    generateManifest,
    scrollToActiveTrack,
    scrollToTop,
    setTheme,
    shuffleArray,
    uploadManifest,
} from '../../utils';
import { YoutubeModal } from './youtube-modal';
import { MenuModal } from './menu-modal';

import '../../styles/player.scss';
import { TrackModal } from './track-modal';
import { AccountModal } from './account-modal';

const Player = () => {
    // const [config, setConfig] = useState(undefined);
    const [playlistData, setPlaylistData] = useState(window?.playlist);
    const [trackIndex, setTrackIndex] = useState(undefined);
    const [currentTrack, setCurrentTrack] = useState(undefined);
    const [duration, setDuration] = useState(0);
    const [tracks, setTracks] = useState([]);
    const [filteredTracks, setFilteredTracks] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [modal, setModal] = useState({});
    const [manifestBlob, setManifestBlob] = useState(undefined);
    const [playlists, setPlaylists] = useState(window?.playlists ?? []);
    const [loading, setLoading] = useState();
    const [timeProgress, setTimeProgress] = useState(0);

    const { playlist, song } = useParams();

    // Swipe effects
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            let newIndex = trackIndex - 1;
            if (newIndex < 0 || newIndex >= tracks.length) {
                newIndex = 0;
            }
            setTrackIndex(newIndex);
            setCurrentTrack(filteredTracks[newIndex]);
        },
        onSwipedRight: () => {
            let newIndex = trackIndex + 1;
            if (newIndex < 0 || newIndex >= tracks.length) {
                newIndex = 0;
            }
            setTrackIndex(newIndex);
            setCurrentTrack(filteredTracks[newIndex]);
        },
    });

    // autoplay song if url parameter
    useEffect(() => {
        if (song && filteredTracks.length) {
            const songName = atob(song);
            const trackIndex = filteredTracks
                .map((track) => track.name)
                .indexOf(songName);

            if (trackIndex !== -1) {
                setCurrentTrack(filteredTracks[trackIndex]);
            }
        }
    }, [song, filteredTracks]);

    useEffect(() => {
        scrollToActiveTrack();
    }, [isPlaying, trackIndex, currentTrack]);

    useEffect(() => {
        if (playlistData) {
            const shouldGenerateManifest =
                playlistData?.name || playlistData?.root;
            if (shouldGenerateManifest) {
                const manifest = generateManifest({
                    ...playlistData,
                    id: playlist,
                });
                uploadManifest({ manifest, manifestBlob, setManifestBlob });
            }

            document
                .querySelector('#favicon')
                .setAttribute(
                    'href',
                    generateEmojiLink(
                        playlistData.iconColor,
                        playlistData.emoji,
                        64
                    )
                );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistData, playlist]);

    // useEffect(() => {
    //   if (trackIndex !== undefined) {
    //     const nextSong = filteredTracks?.[trackIndex + 1];
    //     if (nextSong) {
    //       const link = document.createElement("link");
    //       link.setAttribute("rel", "prefetch");
    //       link.setAttribute("href", nextSong.src);
    //       document.body.append(link);
    //     }
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [trackIndex]);

    // MAIN
    const initialisePlayer = (forceNetwork = false) => {
        if (!forceNetwork && window?.playlist?.id) {
            const { tracks, ...playlistData } = window.playlist;

            setTracks(tracks);
            setFilteredTracks(tracks);
            setCurrentTrack(tracks[trackIndex]);
            setPlaylistData(playlistData);
        } else {
            getPlaylist(playlist).then(({ data }) => {
                if (!data.success) {
                    return;
                }
                const newTracks = data.data.tracks.filter((t) => !t.isFolder);

                setTracks(newTracks);
                setFilteredTracks(newTracks);
                setCurrentTrack(newTracks[trackIndex]);
                setPlaylistData(data.data);
            });
        }

        if (!forceNetwork && window.playlists?.length) {
            setPlaylists(window.playlists);
        } else {
            getAllPlaylists().then(({ data }) => {
                setPlaylists(data.data);
            });
        }
    };
    useEffect(() => {
        initialisePlayer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reloadCurrentPlaylist = (callback = undefined) => {
        getPlaylist(playlist).then(({ data }) => {
            if (!data.success) {
                return;
            }
            const newTracks = data.data.tracks.filter((t) => !t.isFolder);

            setTracks(newTracks);
            setFilteredTracks(newTracks);
            setCurrentTrack(newTracks[trackIndex]);
            setPlaylistData(data.data);

            callback?.();
        });
    };

    useEffect(() => {
        if (playlistData) {
            updatePlaylist(playlistData);
            setTheme(playlistData.theme);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistData]);

    useEffect(() => {
        if (modal && playlistData) {
            setTheme(playlistData.theme);
        }
    }, [modal, playlistData]);

    const updatePlaylist = (updatedPlaylist) => {
        const newPlaylists = playlists.map((playlist) => {
            if (playlist?.id === updatedPlaylist?.id) {
                return {
                    ...playlist,
                    ...updatedPlaylist,
                };
            }

            return playlist;
        });

        setPlaylists(newPlaylists);
    };

    const selectTrackIndex = (index) => {
        const i = index % tracks.length;
        setTrackIndex(i);
        setCurrentTrack(filteredTracks[i]);
        setIsPlaying(true);
    };

    const shuffle = () => {
        const newTracks = shuffleArray(tracks);
        setFilteredTracks(newTracks);
        setTrackIndex(0);
        setCurrentTrack(newTracks[0]);
        setIsPlaying(true);
    };
    const deShuffle = () => {
        setFilteredTracks(tracks);
        setTrackIndex(0);
        setCurrentTrack(tracks[0]);
        setIsPlaying(true);
    };

    const sortByLatest = () => {
        const sortedTracks = [...tracks].sort((a, b) => {
            const A = a.date;
            const B = b.date;
            if (A === B) {
                return 0;
            }
            // reverse
            return A > B ? -1 : -0;
        });

        setFilteredTracks(sortedTracks);
        setTrackIndex(0);
        setCurrentTrack(sortedTracks[0]);
        // setIsPlaying(true);
    };

    const filterTracks = (query) => {
        if (!query) {
            setFilteredTracks(tracks);
            return;
        }

        const newTracks = tracks.filter(
            (track) =>
                track.displayName.toLowerCase().includes(query.toLowerCase()) ||
                track.title.toLowerCase().includes(query.toLowerCase()) ||
                track.artist.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredTracks(newTracks);
        scrollToTop();
    };

    if (modal) {
        document.querySelector('body').classList.add('body--with-modal');
    } else {
        document.querySelector('body').classList.remove('body--with-modal');
    }

    const options = () => (
        <MainToolbar {...{ modal, setModal, currentTrack, playlistData }} />
    );
    const toolBar = () => (
        <PlaylistToolbar
            {...{
                filterTracks,
                shuffle,
                deShuffle,
                sortByLatest,
                isEmpty: tracks?.length && filteredTracks?.length === 0,
                playlistData,
                setPlaylistData,
                reloadCurrentPlaylist,
            }}
        />
    );

    return (
        <>
            <div className="page Player">
                <Playlist
                    swipeHandlers={swipeHandlers}
                    tracks={filteredTracks}
                    setTrack={setCurrentTrack}
                    currentTrack={currentTrack}
                    selectTrackIndex={selectTrackIndex}
                    viewType={playlistData?.viewType}
                    isEmpty={tracks?.length && filteredTracks?.length === 0}
                    playlistData={playlistData}
                    setModal={setModal}
                />
                <Controls
                    {...{
                        options: options,
                        toolBar: toolBar,
                        currentTrack,
                        duration,
                        isPlaying,
                        setIsPlaying,
                        setCurrentTrack,
                        setDuration,
                        setTrackIndex,
                        timeProgress,
                        setTimeProgress,
                        trackIndex,
                        tracks: filteredTracks,
                        playlistData: playlistData,
                    }}
                />
            </div>

            {modal?.modal === 'playlists' && (
                <PlaylistsModal
                    {...{
                        playlists,
                        playlistData,
                        clearModal: () => setModal({}),
                    }}
                />
            )}
            {modal?.modal === 'downloader' && (
                <YoutubeModal
                    setLoading={setLoading}
                    isLoading={loading}
                    selectedPlaylist={playlistData?.id}
                    clearModal={() => setModal({})}
                    playlists={playlists}
                />
            )}

            {modal?.modal === 'track' && (
                <TrackModal
                    payload={modal?.payload}
                    setLoading={setLoading}
                    clearModal={() => setModal({})}
                    playlistData={playlistData}
                    selectedPlaylistId={playlistData?.id}
                    playlists={playlists}
                    track={tracks.find(
                        (track) => track.id === modal?.payload?.trackId
                    )}
                    initialisePlayer={initialisePlayer}
                />
            )}
            {modal?.modal === 'menu' && (
                <MenuModal
                    setLoading={setLoading}
                    setModal={setModal}
                    clearModal={() => setModal({})}
                />
            )}
            {modal?.modal === 'account' && (
                <AccountModal
                    setLoading={setLoading}
                    setModal={setModal}
                    clearModal={() => setModal({})}
                    userId={playlistData?.userId}
                    user={playlistData?.user}
                    playlist={playlistData}
                />
            )}
            {loading && (
                <div className={'Loading'}>
                    <div className={'Loading__container'}></div>

                    <div className={'Loading__icon rotating'}>
                        {playlistData.emoji}
                    </div>
                    <div className={'Loading__title'}>{loading?.title}</div>
                    <div className={'Loading__message'}>{loading?.message}</div>
                </div>
            )}
        </>
    );
};
export default Player;
