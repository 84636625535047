import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import '../../styles/login.scss';
import { useState, useEffect } from 'react';
// import axios from 'axios';
import { login } from '../../api';

export const Login = () => {
    const [googleUser, setGoogleUser] = useState(undefined);

    const runLogin = useGoogleLogin({
        onSuccess: (codeResponse) => setGoogleUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error),
    });

    useEffect(() => {
        if (googleUser) {
            // axios
            //     .get(
            //         `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
            //         {
            //             headers: {
            //                 Authorization: `Bearer ${googleUser.access_token}`,
            //                 Accept: 'application/json',
            //             },
            //         }
            //     )
            //     .then((res) => {
            //         setProfile(res.data);
            //     })
            //     .catch((err) => console.log(err));
            login(googleUser.access_token).then(({ data }) => {
                if (data.success) {
                    window.location.href = '/';
                } else {
                    googleLogout();
                }
            });
        }
    }, [googleUser]);

    return (
        <div className={'Login'}>
            <div className={'Login__block'}>
                <h1>MP3 Player</h1>
                <h2>by tekbreak</h2>
                <div className={'Login__button'} onClick={() => runLogin()}>
                    Iniciar con Google
                </div>
            </div>
        </div>
    );
};

// export const getDecodedOAuthJwtGoogle = async (token) => {
//     const CLIENT_ID_GOOGLE =
//         '20043882366-e97u3f3g9povi47qldsq2p9n868sc7dh.apps.googleusercontent.com';
//
//     try {
//         const client = new OAuth2Client(CLIENT_ID_GOOGLE);
//
//         const ticket = await client.verifyIdToken({
//             idToken: token,
//             audience: CLIENT_ID_GOOGLE,
//         });
//
//         return ticket;
//     } catch (error) {
//         return { status: 500, data: error };
//     }
// };

/*

email
    :
    "tekbreak@gmail.com"
given_name
    :
    "Tekbreak"
id
    :
    "117617850597890456577"
name
    :
    "Tekbreak"
picture
    :
    "https://lh3.googleusercontent.com/a/ACg8ocI5gJdOv2HoxmnjznYVDrTnfBUbsZlEGs3OesksBK96SjkLrPPS=s96-c"
verified_email
    :
    true

 */
