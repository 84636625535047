import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const AccountModal = ({ playlist, user, userId, clearModal }) => {
    const [googleUser, setGoogleUser] = useState(undefined);
    const [profile, setProfile] = useState(undefined);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setGoogleUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error),
    });

    const updateUserDetails = () => {};

    useEffect(() => {
        if (googleUser) {
            axios
                .get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json',
                        },
                    }
                )
                .then((res) => {
                    setProfile(res.data);
                })
                .catch((err) => console.log(err));
        }
    }, [googleUser]);

    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    return (
        <div className="Modal Menu">
            <div className="Modal--header">
                <div className="Modal--close" onClick={() => clearModal()}>
                    <i className="fa fa-angle-double-down" />
                </div>
                <div className="Modal--title">
                    <h1>Account</h1>
                </div>
            </div>

            <div className={'Modal__container'}>
                <div className={'Youtube__search'}></div>

                <div>
                    <h2>React Google Login</h2>
                    <br />
                    <br />
                    {profile ? (
                        <div>
                            <img src={profile.picture} alt="user image" />
                            <h3>User Logged in</h3>
                            <p>Name: {profile.name}</p>
                            <p>Email Address: {profile.email}</p>
                            <br />
                            <br />
                            <button onClick={logOut}>Log out</button>
                        </div>
                    ) : (
                        <button onClick={() => login()}>
                            Sign in with Google 🚀{' '}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
