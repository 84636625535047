const MENU = [
    {
        name: 'Añadir música',
        modal: 'downloader',
    },

    {
        name: 'Listas de Reproducción',
        modal: 'playlists',
    },
    {
        name: 'Tu Cuenta',
        modal: 'account',
    },
];

export const MenuModal = ({ setModal, clearModal }) => {
    return (
        <div className="Modal Menu">
            <div className="Modal--header">
                <div className="Modal--close" onClick={() => clearModal()}>
                    <i className="fa fa-angle-double-down" />
                </div>
                <div className="Modal--title">
                    <h1>Menu</h1>
                </div>
            </div>
            <div className={'Modal__container'}>
                <ul className={'Menu'}>
                    {MENU.map((item) => (
                        <li
                            onClick={() => {
                                setModal({ modal: item.modal });
                            }}
                        >
                            {item.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
